@use 'util';
// @use 'bootstrap/scss/bootstrap';
@import 'tippy.js/dist/tippy.css';
@tailwind components;
@tailwind utilities;

body,
#__next {
    width: 100%;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
}

.editor,
.dashboard {
    height: 100%;
    overflow: hidden;
}

a:hover {
    cursor: pointer;
}

a {
    text-decoration: none;
    &:visited {
        color: map-get(util.$colors, 'primary');
    }
}

hr {
    clear: both;
    display: block;
    width: 100%;
}

/* Tippy Tooltips */
.tippy-box[data-theme~='dark'] {
    background-color: map-get(util.$colors, 'dark');
    color: map-get(util.$colors, 'light');

    &[data-placement^='top'] > .tippy-arrow::before {
        border-top-color: map-get(util.$colors, 'dark');
    }
    &[data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color: map-get(util.$colors, 'dark');
    }
    &[data-placement^='left'] > .tippy-arrow::before {
        border-left-color: map-get(util.$colors, 'dark');
    }
    &[data-placement^='right'] > .tippy-arrow::before {
        border-right-color: map-get(util.$colors, 'dark');
    }

    & > .tippy-svg-arrow {
        fill: map-get(util.$colors, 'dark');
    }
}

// add here to remove the default shadow/border on focus on Chrome
:focus {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
}

/*Primary color scrollbar */
// Width
::-webkit-scrollbar {
    width: 6px;
}

// Handle
::-webkit-scrollbar-thumb {
    background-color: map-get(util.$colors, 'grey');
    border-radius: 4px;
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
    background-color: map-get(util.$colors, 'dark');
}

h1 {
    line-height: 3rem;
    letter-spacing: 0em;
}

h2 {
    line-height: 2.75rem;
    letter-spacing: 0.01em;
}

h3 {
    line-height: 2.5rem;
    letter-spacing: 0.01em;
}

h4 {
    line-height: 2.25rem;
    letter-spacing: 0.02em;
}

h5 {
    line-height: 1.75rem;
    letter-spacing: 0em;
}

h6 {
    line-height: 1.75rem;
    letter-spacing: 0.02em;
}

.firebase-emulator-warning {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    margin: 0;
}

p {
    margin: 0;
}

// z-index list - make sure that one follows the other
:root {
    --z-base: 0;
    --z-editor: calc(var(--z-base) + 1);
    --z-panel: calc(var(--z-editor) + 1);
    --z-panel-toggle: calc(var(--z-panel) + 1);
    --z-preview: calc(var(--z-panel-toggle) + 1);
    --z-progress-bar: calc(var(--z-preview) + 1);
    --z-navbar: calc(var(--z-progress-bar) + 1);
    --z-toolbar: calc(var(--z-navbar) + 1);
    --z-footer: calc(var(--z-toolbar) + 1);
    --z-toast: calc(var(--z-footer) + 1);
    --z-banner: calc(var(--z-toast) + 1);
    --z-editor-tooltip: calc(var(--z-banner) + 1);
    --z-modal: calc(var(--z-editor-tooltip) + 1);
}
